$primary: #fbf4e3;
$secondary: #e6f1ef;
$dark: #93864e;
$green: #2b544f;
$body-color: $green;
$border-radius: 0rem;
$border-radius-lg: 0rem;
$border-radius-sm: 0rem;
$navbar-dark-active-color: $green;
$font-family-sans-serif: "Poppins", sans-serif;
$light: #fbf4e3;
$link-color: $green;
$navbar-nav-link-padding-x: 2em;

/* the above are all from: https://bootstrap.build/app as _variables.scss */

$light-border: #e5dcb5;
$light-outlines: #c4b36d;

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

h1 {
  font-weight: bolder;
}

h4 {
  font-weight: bolder;
}

.encouragement {
  font-family: gastromond, sans-serif;
  color: $light-outlines;
}

.content-area {
  padding-top: 1em;
  padding-bottom: 1em;
}

.custom-primary-btn {
  position: relative;
}

.btn-primary {
  color: $green;
  font-weight: bold;
  border: 0.1em solid $light-outlines;
}

.btn-primary:hover {
  color: rgba($green, 0.5);
  font-weight: bolder;
  border: 0.1em solid rgba($light-outlines, 0.5);
  background-color: rgba($light-border, 0.5);
}

.btn-primary-bottom-border {
  background-color: $light-border;
  height: 0.2em;
  width: 100%;
  bottom: 0em;
  left: 0em;
  position: absolute;
}

.accordion .card .card-header {
  cursor: pointer;
  text-decoration: underline;
}

.accordion {
  .card {
    .card-header {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
