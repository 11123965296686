
.style-card {
    min-height: 24em;
    max-height: 24em;
    min-width: 24em;
    max-width: 24em;
    width: 24em;
    height: 24em;
    margin-bottom: 2em;
}


.style-card-image {
    height: 10em;
    width: auto;
    object-fit: cover;
}